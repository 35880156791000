import React, { Suspense, lazy } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import Loading from '../Loading';
import { useAuth } from '../../context/AuthContext';
import './index.css';

const AuthenticatedApp = lazy(() =>
  import(/* webpackPrefetch: true */ '../AuthenticatedApp')
);

const UnauthenticatedApp = lazy(() => import('../UnauthenticatedApp'));

export default function App() {
  const { user } = useAuth();
  return (
    <>
      <Suspense fallback={<Loading />}>
        {user ? <AuthenticatedApp /> : <UnauthenticatedApp />}
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </>
  );
}
