import axios from 'axios';
import { useQuery } from 'react-query';
import * as qs from 'qs';
import moment from 'moment';

export default function useTotalCenteredRuns(queryParams) {
  const { startDate, endDate, customerSiteIds, panelVersion } =
    queryParams;

  return useQuery(
  [
    'total-centered-runs',
    startDate,
    endDate,
    customerSiteIds,
    panelVersion,
  ], () => fetchTotalCenteredRuns(queryParams));
}

async function fetchTotalCenteredRuns(queryParams) {
  const { startDate, endDate, panelVersion, customerSiteIds } =
    queryParams;
  const response = await axios.post('/api/sites/totalCenteredRuns', {
    StartDate: moment(startDate).format('YYYY-MM-DD'),
    EndDate: moment(endDate).format('YYYY-MM-DD'),
    Panel: panelVersion,
    CustomerSiteIds: customerSiteIds,
  });

  return response.data;
}