import React from 'react';
import './index.css';
import loadingImage from '../../assets/loading.svg';

export default function Loading() {
  return (
    <div className="loading-indicator">
      <div className="overlay" />
      <div className="spinner">
        <img src={loadingImage} alt="" />
      </div>
    </div>
  );
}
