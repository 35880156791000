import axios from 'axios';
import Auth from '@aws-amplify/auth';
import { API_BASE_URL } from '../config';

axios.defaults.baseURL = API_BASE_URL;

axios.interceptors.request.use(
  async config => {
    try {
      const session = await Auth.currentSession();
      if (session) {
        const token = session.idToken.jwtToken;
        config.headers.Authorization = `Bearer ${token}`;
      }
    } catch (error) {
      return Promise.reject(error);
    }
    return config;
  },
  error => Promise.reject(error)
);

export default axios;
