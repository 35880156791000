import React, { useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import Auth from '@aws-amplify/auth';
import Loading from '../../components/Loading';
import axios from '../../infrastructure/axios';
import { useAsync } from '../../hooks';

const AuthContext = React.createContext();
AuthContext.displayName = 'AuthContext';

async function fetchAccountInfo() {
  const response = await axios.get('/api/account');
  return response.data;
}

async function bootstrapUserData(queryClient) {
  let user = null;
  try {
    const session = await Auth.currentSession();
    if (session) {
      const { email, phone_number, name } = session.idToken.payload;
      user = await fetchAccountInfo();
      queryClient.setQueryData('account-info', user, {
        staleTime: 5000,
      });
      return { email, phone_number, name, ...user };
    }
  } catch {
    return user;
  }
  return user;
}

function AuthProvider(props) {
  const navigate = useNavigate();
  const {
    data: user,
    status,
    error,
    isLoading,
    isIdle,
    isError,
    isSuccess,
    run,
    setData,
  } = useAsync();
  const queryClient = useQueryClient();

  useEffect(() => {
    const userDataPromise = bootstrapUserData(queryClient);
    run(userDataPromise);
  }, [run]);

  const login = useCallback(
    form =>
      Auth.signIn(form).then(async() => {
        const permissions = await fetchAccountInfo();
        queryClient.setQueryData('account-info', permissions, {
          staleTime: 5000,
        });
        setData(permissions);
        navigate('/');
      }),
    [setData]
  );

  const logout = useCallback(() => {
    Auth.signOut();
    queryClient.clear();
    setData(null);
    navigate('/');
  }, [setData]);

  const value = useMemo(() => ({ user, login, logout }), [login, logout, user]);

  if (isLoading || isIdle) {
    return <Loading />;
  }

  if (isError) {
    // TODO Add error component
    throw new Error(`Unhandled error: ${error}`);
  }

  if (isSuccess) {
    return <AuthContext.Provider value={value} {...props} />;
  }

  throw new Error(`Unhandled status: ${status}`);
}

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth, fetchAccountInfo };
