import axios from 'axios';
import { useQuery } from 'react-query';

export default function useViewableSites() {
  return useQuery('viewable-sites', fetchViewableSites);
}

async function fetchViewableSites() {
  const response = await axios.get('/api/sites/viewable');
  return response.data;
}
