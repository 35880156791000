import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable/symbol';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.less';
import App from './components/App';
import AppProviders from './infrastructure/providers';

import { fetchConfiguration } from './config';

function renderApp() {
  ReactDOM.render(
    <React.StrictMode>
      <AppProviders>
        <App />
      </AppProviders>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

fetchConfiguration().then(async ()=>{
  await import('./infrastructure/i18n');
  await import('./infrastructure/amplify');
}).then(renderApp);
