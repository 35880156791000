export let API_BASE_URL;
export let AWS_REGION;
export let AWS_USERPOOLID;
export let AWS_USERPOOL_WEBCLIENTID;

export const fetchConfiguration = async() => {
  if (process?.env?.REACT_APP_API_BASE_URL) {
    API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
    AWS_REGION = process.env.REACT_APP_AWS_REGION;
    AWS_USERPOOLID = process.env.REACT_APP_AWS_USERPOOLID;
    AWS_USERPOOL_WEBCLIENTID = process.env.REACT_APP_AWS_USERPOOL_WEBCLIENTID;
    return Promise.resolve();
  } else {
    return await fetch(`/Configuration.json?v=${window.cacheBust}`)
      .then(response => response.json())
      .then(data => {
        API_BASE_URL = data.API_BASE_URL;
        AWS_REGION = data.AWS_REGION;
        AWS_USERPOOLID = data.AWS_USERPOOLID;
        AWS_USERPOOL_WEBCLIENTID = data.AWS_USERPOOL_WEBCLIENTID;
      });
  }
};
